<template>
  <div class="cover-wrapper" :class="{ cover: !isWxOver }">
    <div class="cover-box" v-if="isWxOver">
      <img :src="src" />
      <div class="text-tip">
        <span>1.</span>
        点击右上角更多<i class="el-icon-more"></i>按钮
      </div>
      <div class="text-tip">
        <span>2.</span>
        选择在 <i>浏览器中打开</i> 完成支付
      </div>
      <!-- <div class="text-tip">
        <span>3.</span>
        或者点击下方按钮 <i> 复制链接</i> 在外部浏览器打开完成支付
      </div>
      <div class="text-tip">
        <span>4.</span>
        <i> 付款完成</i> 点击验证付款状态，获取二维码
      </div> -->
    </div>
    <template v-if="$route.name === 'Wxpay' && !isWxOver">
      <a class="return-wx-btn" href="weixin://">已完成付款</a>
      <p class="wx-tip-text">
        如果你已经完成付款，请点击「已完成付款」按钮，点击后将返回商家。
      </p>
    </template>
  </div>
</template>

<script>
import { buyOrderUrl, getWxUlr } from "@/api/index.js";

export default {
  name: "alipay_wxpay",
  data() {
    return {
      src: require("@/assets/right-b.gif"),
      orderId: "",
      copyUrl: "",
      isWxOver: true
    };
  },
  watch: {
    copyUrl(val) {
      this.$emit("resove-url", val);
    }
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.isWxOver = this.isWx();
    if (this.$route.name === "Alipay") {
      this.alipayHandle();
    } else if (this.$route.name === "Wxpay") {
      this.wxpayHandle();
    }
  },
  methods: {
    wxpayHandle() {
      var fp = new window.Fingerprint2();
      fp.get(result => {
        window.$.getJSON(getWxUlr(this.orderId, result), d => {
          if (d.mwebUrl) {
            this.copyUrl = location.href;
            if (!this.isWxOver) {
              window.location = d.mwebUrl;
            }
          } else {
            this.$message.warning(d.message);
          }
        });
      });
    },
    alipayHandle() {
      this.copyUrl = buyOrderUrl(this.orderId);
      if (!this.isWxOver) {
        window.location = this.copyUrl;
      }
    },
    isWx() {
      //判断是否为微信
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.includes("micromessenger")) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.cover-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 61px);
  background-color: #ffffff;
  z-index: 100;
  &.cover {
    height: 100vh;
  }
  .return-wx-btn {
    display: block;
    margin: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 384px;
    height: 51px;
    line-height: 43px;
    text-align: center;
    border-radius: 1.5px;
    border: 1px solid #24af41;
    color: #24af41;
    background: #ffffff;
    font-size: 18px;
    padding: 4px 8px;
    margin: 100px 40px 40px;
  }
  .wx-tip-text {
    font-size: 14px;
    padding: 0 40px;
    color: #999999;
  }
  .cover-box {
    min-height: 100%;
    background-color: #ffffff;
    padding-top: 1px;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: 30px;
    margin-top: 30px;
  }
  .text-tip {
    font-size: 20px;
    display: block;
    margin-top: 15px;
    padding: 0 40px;
    .el-icon-more {
      color: #179af5;
      padding: 0 5px;
    }
    i {
      color: #179af5;
      font-style: normal;
    }
    span {
      display: inline-block;
      padding-right: 5px;
    }
  }
}
</style>
